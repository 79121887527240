import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import reduce from 'lodash/reduce'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'
import moment from 'moment'
import Web3 from 'web3'
import { TOKEN_TYPES, PROSPECTUS_OR_ISSUING, DISCOUNT_PHASE } from '../../../constants/settings'
import allLiquidationSetting from '../../../constants/liquidation'
import { ACCEPTED_CURRENCIES } from '../../../constants/contribute'
import {
  DATE_UPLOAD_COPY_CSV,
  GET_AVAILABLE_FEATURES,
} from '../../../queriesAndMutations'
import {
  extractMsgFromErrByPropertyName,
  isFeatureEnabled,
  isAllRequiredFeaturesEnabled,
  getFileUrl,
} from '../../../utils'
import { useTranslation } from 'react-i18next'
import FeatureNotAvailableMessage from '../../../components/FeatureNotAvailableMessage'
import LockedFeatureIcon from '../../../components/LockedFeatureIcon'
import ImageUpload from '../../../components/ImageUpload'
import { getOriginalFileExtension } from '../../../utils/file'
import Input from '../../../components/Input'
import InputTheme from '../../../components/InputTheme'
import RadioTheme from '../../../components/Radio'
import SwitchCheckbox from '../../../components/SwitchCheckbox'
import DropdownMaterial from '../../../components/DropdownMaterial'
import { COUNTRY_LIST } from '../../../constants/customer'

const liquidationSetting = allLiquidationSetting[process.env.REACT_APP_ENV]
const SETS = {
  token_type: {
    default: TOKEN_TYPES.EQUITY,
  },
  token_name: {
    default: "",
  },
  token_symbol: {
    default: "",
  },
  rename_share_to_token: {
    default: false,
  },
  base_currency: {
    default: "",
  },
  minimum_investment_amount: {
    default: 1,
  },
  maximum_investment_amount: {
    default: 1,
  },
  enforce_token_minimum_at_kyc: {
    default: false,
  },
  prospectus_date: {
    default: new Date("01.01.1970"),
  },
  issuing_guidelines_date: {
    default: new Date("01.01.1970"),
  },
  memorandum_date: {
    default: new Date("01.01.1970"),
  },
  token_safe_date: {
    default: new Date("01.01.1970"),
  },
  prospectus_url: {
    default: '',
  },
  token_sale_agreement_url: {
    default: '',
  },
  memorandum_url: {
    default: '',
  },
  token_safe_url: {
    default: '',
  },
  google_analytics_id: {
    default: "",
  },
  google_tag_manager_id: {
    default: "",
  },
  pixel_id: {
    default: "",
  },
  video_ident_value: {
    default: 15000,
  },
  interval_upload_csv_pythagoras: {
    default: 1,
  },
  kyc_text: {
    default: "Hello!",
  },
  prospectus_or_issuing: {
    default: PROSPECTUS_OR_ISSUING.PROSPECTUS,
  },
  issuing_guidelines: {
    default: new Date("01.01.1970"),
  },
  token_sale_agreement_date: {
    default: new Date("01.01.1970"),
  },
  login_youtube_video: {
    default: "",
  },
  login_youtube_text: {
    default: "",
  },
  first_link_login: {
    default: "",
  },
  first_text_login: {
    default: "",
  },
  second_link_login: {
    default: "",
  },
  second_text_login: {
    default: "",
  },
  not_video_ident_countries: {
    default: {},
  },
  high_risk_countries: {
    default: {},
  },
  medium_risk_countries: {
    default: {},
  },
  enhanced_risk_countries: {
    default: {},
  },
  show_source_signup: {
    default: false,
  },
  send_gto_api_when_changing_status: {
    default: false,
  },
  add_ether_wallet_later_option: {
    default: false,
  },
  kyc_occupation: {
    default: false,
  },
  kyc_yearly_salary: {
    default: false,
  },
  kyc_employer_company_name: {
    default: false,
  },
  kyc_education_level: {
    default: false,
  },
  internal_sales_api: {
    default: "",
  },
  internal_sales_api_key: {
    default: "",
  },
  sendwyre_api_key: {
    default: "",
  },
  sendwyre_api_secret: {
    default: "",
  },
  sendwyre_account_id: {
    default: "",
  },
  sendwyre_payment_type: {
    default: "enable_wallet",
  },
  enable_cryptodotcom: {
    default: false,
  },
  crypto_api_pk: {
    default: "",
  },
  crypto_api_sk: {
    default: "",
  },
  hotjar_id: {
    default: "",
  },
  intercom_app_id: {
    default: "",
  },
  intercom_region: {
    default: "US",
  },
  kyc_referal_code: {
    default: false,
  },
  enable_kyc_discount_level: {
    default: false,
  },
  kyc_discount_phase: {
    default: DISCOUNT_PHASE[0].id,
  },
  purchase_agreement_link: {
    default: "",
  },
  enable_subscription_agreement_link: {
    default: false,
  },
  subscription_agreement_link: {
    default: "",
  },
  enable_stripe: {
    default: false,
  },
  stripe_public_key: {
    default: "",
  },
  stripe_secret_key: {
    default: "",
  },
  enable_allow_list_countries: {
    default: false,
  },
  allow_list_countries_choosen: {
    default: ""
  },
  enable_allow_list_nationality: {
    default: false,
  },
  allow_list_nationality_choosen: {
    default: ""
  },
  enable_voltio: {
    default: false,
  },
  voltio_url: {
    default: "",
  },
  voltio_client_id: {
    default: "",
  },
  voltio_client_secret: {
    default: "",
  },
  voltio_password: {
    default: "",
  },
  voltio_username: {
    default: "",
  },
  voltio_account: {
    default: "",
  },
  enable_monerium: {
    default: false,
  },
  monerium_client_id: {
    default: "",
  },
  monerium_client_secret: {
    default: "",
  },
  signnow_setting_type: {
    default: "predefined"
  },
  signnow_api_key: {
    default: "",
  },
  template_id_required_signature: {
    default: "",
  },
  signnow_account_email: {
    default: "",
  },
  signnow_account_password: {
    default: "",
  },
  signnow_template_name: {
    default: "",
  },
  signnow_company_form_c_1_signature_template_id: {
    default: "",
  },
  signnow_company_form_c_2_signature_template_id: {
    default: "",
  },
  signnow_company_form_c_3_signature_template_id: {
    default: "",
  },
  signnow_company_form_c_4_signature_template_id: {
    default: "",
  },
  signnow_company_form_t_1_signature_template_id: {
    default: "",
  },
  signnow_company_form_t_2_signature_template_id: {
    default: "",
  },
  signnow_company_form_t_3_signature_template_id: {
    default: "",
  },
  signnow_company_form_t_4_signature_template_id: {
    default: "",
  },
  signnow_individual_form_swap_token_template_id: {
    default: "",
  },
  signnow_company_form_swap_token_template_id: {
    default: "",
  },
  signnow_individual_form_token_sale_agreement_id: {
    default: "",
  },
  signnow_company_form_token_sale_agreement_id: {
    default: "",
  },
  signnow_token_safe_id: {
    default: "",
  },
  enable_acronis: {
    default: false,
  },
  acronis_client_id: {
    default: "",
  },
  acronis_client_secret: {
    default: "",
  },
  acronis_account_login: {
    default: "",
  },
  acronis_account_password: {
    default: "",
  },
  acronis_root_folder: {
    default: "",
  },
  enable_sync_pythagoras: {
    default: false
  },
  enable_transaction_limit: {
    default: false
  },
  transaction_limit_quota: {
    default: 0
  },
  capital_increase_nominal_value: {
    default: 0
  },
  require_exact_investment_amount_on_none: {
    default: false,
  },
  disable_erc_20_check: {
    default: false,
  },
  kyc_require_referral: {
    default: false,
  },
  wallet_service: {
    default: '',
  },
  hide_wallet_format_note: {
    default: false,
  },
  allow_compliance_update_kyc: {
    default: false,
  },
  allow_duplicated_id_kyc: {
    default: false,
  },
  hide_token_balance: {
    default: false,
  },
  show_investhub_dao_login_btn: {
    default: false
  },
  kyc_phone_number: {
    default: true,
  },
  kyc_net_investable_assets: {
    default: true,
  },
  kyc_what_will_you_use: {
    default: true,
  },
  kyc_politically_exposed_persons: {
    default: true,
  },
  kyc_prefer_currency: {
    default: true,
  },
  kyc_intended_investment: {
    default: true,
  },
  kyc_support_source_of_funds: {
    default: true,
  },
  kyc_support_source_of_wealth: {
    default: false,
  },
  public_sale_sof_sow: {
    default: false,
  },
  kyc_acknowledgement_of_receipt: {
    default: true,
  },
  kyc_client_statement: {
    default: true,
  },
  kyc_confirmations: {
    default: true,
  },
  kyc_enable_kyb: {
    default: true,
  },
  kyc_show_receiving_wallet: {
    default: true,
  },
  kyc_show_website: {
    default: false,
  },
  kyc_show_position: {
    default: false,
  },
  thumbnail: {
    default: null,
  },
  show_tenant_on_investhub: {
    default: true,
  },
  liquidation_contract_address: {
    default: '',
  },
  liquidation_holder_addresses: {
    default: '',
  },
  liquidation_compensation_rate: {
    default: 0.081,
  },
  liquidation_specific_compensation_rate: {
    default: '',
  },
  liquidation_token_decimal: {
    default: 18,
  },
  enable_tangany: {
    default: false,
  },
  tangany_setting_type: {
    default: 'predefined',
  },
  tangany_client_id: {
    default: '',
  },
  tangany_client_secret: {
    default: '',
  },
  tangany_vault_url: {
    default: '',
  },
  tangany_subscription: {
    default: '',
  },

  enable_impira: {
    default: false,
  },
  impira_setting_type: {
    default: 'predefined',
  },
  impira_org: {
    default: '',
  },
  impira_token: {
    default: '',
  },
  impira_utility_bill: {
    default: '',
  },

  twilio_setting_type: {
    default: 'predefined',
  },
  twilio_account_sid: {
    default: '',
  },
  twilio_auth_token: {
    default: '',
  },
  twilio_service_sid: {
    default: '',
  },

  deepl_setting_type: {
    default: 'predefined',
  },
  deepl_auth_key: {
    default: '',
  },

  force_user_till_first_transaction: {
    default: false,
  },
  force_generate_wallet: {
    default: false,
  },
  enable_signnow_individual_form: {
    default: false,
  },
  kyc_require_receiving_wallet: {
    default: false,
  },
  kyc_require_industry: {
    default: false,
  },
  kyb_require_activity_description: {
    default: true,
  },
  kyb_require_main_income: {
    default: true,
  },
  kyb_require_other_income: {
    default: true,
  },
  kyb_require_gross_revenue: {
    default: true,
  },
  kyb_require_organization_chart: {
    default: true,
  },
  kyb_require_association_article: {
    default: true,
  },
  kyb_require_last_balance_sheet: {
    default: true,
  },
  kyb_show_good_standing_certificate: {
    default: true,
  },
  kyb_require_shareholder_list: {
    default: true,
  },
  kyb_require_ubo: {
    default: true,
  },
  kyb_require_director_form: {
    default: false,
  },
  kyb_skip_confirm_beneficial_ownership: {
    default: false,
  },
  kyb_require_utility_bill: {
    default: true,
  },
  kyb_show_business_countries: {
    default: false,
  },
  kyb_show_business_following_industries: {
    default: false,
  },
  kyc_skip_legal_disclaimer: {
    default: false,
  },
  kyc_require_legal_protection_insurance: {
    default: false,
  },
  use_registration_confirm_for_proof_of_residence: {
    default: false,
  },
  enable_proof_of_residence_checking: {
    default: false,
  },
  show_verify_wallet: {
    default: false,
  },
  allow_sync_kyc_data_investhub: {
    default: false,
  },
  discount_maximum_raise: {
    default: '',
  },
  discount_end_date: {
    default: new Date("01.01.1970"),
  }
};

const AdminSettingsIco = ({
  settings,
  loading,
  error,
  onUpdate,
  editingAllowed,
}) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({
    ...reduce(
      SETS,
      (memo, value, key) => {
        if (
          key === "prospectus_date" ||
          key === "issuing_guidelines" ||
          key === "token_sale_agreement_date" ||
          key === "issuing_guidelines_date" ||
          key === "memorandum_date"
        ) {
          // eslint-disable-next-line no-param-reassign
          memo[key] =
            (settings[key] && moment(settings[key]).format("YYYY-MM-DD")) ||
            moment(SETS[key].default).format("YYYY-MM-DD");
          return memo;
        }
        if (key === "liquidation_token_decimal") {
          // eslint-disable-next-line no-param-reassign
          memo[key] = settings[key] === undefined ? SETS[key].default : `${settings[key]}`;
          return memo;
        }

        // eslint-disable-next-line no-param-reassign
        memo[key] = settings[key] === undefined ? SETS[key].default : settings[key];
        return memo;
      },
      {}
    ),
  });

  const currentHost = window.location.host;
  const isAwearetech = currentHost === 'kyc.awearetech.li' || currentHost === 'aweare-dev.investhub.io' || currentHost === 'localhost:3000';

  const fetchDecimal = async (address) => {
    try {
      const web3 = new Web3(window.ethereum)
      const contract = new web3.eth.Contract(liquidationSetting.abi, address)

      return await contract.methods.decimals().call()
    } catch (error) {
      return null
    }
  }

  const { data: { getAvailableFeatures: availableFeatures } = {} } = useQuery(GET_AVAILABLE_FEATURES);

  const {
    data: { dateUploadCopyCsv = {} } = {},
    loading: loadingLastUploadCsv = false,
    error: errorLastUploadCsv,
  } = useQuery(DATE_UPLOAD_COPY_CSV);

  const [acceptedCurrencies, setAcceptedCurrencies] = useState({
    eth: true,
    eur: true,
    chf: true,
    usd: true,
    usdt: true,
    xdc: false,
    polygon: false,
    dai: false,
    usdc: false,
    busd: false,
    eure: false,
    ...(settings.accepted_currencies || {}),
  });

  const [daysForNotifications, setDaysForNotifications] = useState({
    signUpWithoutKYC: 3,
    transactionWithoutKYC: 3,
    ...(settings.days_for_notifications || {}),
  });

  const [checkedAllowlistCountries, setCheckedAllowlistCountries] = useState((settings.allow_list_countries_choosen || '').split(',').filter(Boolean).map(x => String(x).trim()))
  const [checkedAllowlistNationality, setCheckedAllowlistNationality] = useState((settings.allow_list_nationality_choosen || '').split(',').filter(Boolean).map(x => String(x).trim()))

  // Add/Remove checked Allow List Countries from the list citizen
  const handleCheckAllowlistCountries = (event) => {
    var updateList = [...checkedAllowlistCountries]
    if (event.target.checked){
      updateList = [...checkedAllowlistCountries, event.target.value]
    } else {
      updateList.splice(checkedAllowlistCountries.indexOf(event.target.value), 1)
    }
    setCheckedAllowlistCountries(updateList)
  }

  const handleCheckAllowlistNationality = (event) => {
    var updateList = [...checkedAllowlistNationality]
    if (event.target.checked){
      updateList = [...checkedAllowlistNationality, event.target.value]
    } else {
      updateList.splice(checkedAllowlistNationality.indexOf(event.target.value), 1)
    }
    setCheckedAllowlistNationality(updateList)
  }

  // Generate string of checked items
  const checkedCitizens = checkedAllowlistCountries.length ? checkedAllowlistCountries.join(", ") : ""
  const checkedNationalities = checkedAllowlistNationality.length ? checkedAllowlistNationality.join(", ") : ""

  const sendwyrePaymentTypes = {
    enable_wallet: 'Enable Wallet',
    enable_direct_purchase: 'Enable Direct Purchase',
    enable_nft_smart_onramps: 'Enable NFT Smart Onramps',
  }

  const fieldsDaysForNotifications = {
    signUpWithoutKYC: {
      propertyName: "signUpWithoutKYC",
      label: "Sign up without KYC. Days for notification",
    },
    transactionWithoutKYC: {
      propertyName: "transactionWithoutKYC",
      label: "Transaction without KYC. Days for notification",
    },
  };

  const nameFieldApplication = {
    taxNumber: "Tax number",
    taxOffice: "Tax office",
    photoWithMeUpload: "Upload photo proof document",
    proofOfResidenceUpload: "Upload proof Residence",
    show_source_signup: "Show source registered",
    send_gto_api_when_changing_status:
      "Send info about transactions to GTO endpoint",
    mandatoryKYCOrganic: "Mandatory KYC organic to create transaction",
    mandatoryKYCReferral: "Mandatory KYC referral to create transaction ",
  };

  const [acceptMandatoryKYC, setAcceptMandatoryKYC] = useState({
    mandatoryKYCOrganic: true,
    mandatoryKYCReferral: true,
    ...(settings.accepted_mandatory_kyc || {}),
  });

  const onChangeInput = (name, state, setState) => (event) => {
    setState({ ...state, [name]: +event[name] });
  };

  const onChangeCheckbox = (name, state, setState) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };

  const onUpdateBtnClick = () => {
    const newValues = {
      ...values,
      days_for_notifications: daysForNotifications,
      accepted_currencies: acceptedCurrencies,
      accepted_mandatory_kyc: acceptMandatoryKYC,
    };
    newValues.minimum_investment_amount = parseInt(
      newValues.minimum_investment_amount,
      10
    );
    newValues.video_ident_value = parseInt(newValues.video_ident_value, 10);
    newValues.thumbnail = newValues.thumbnail ? newValues.thumbnail.img : null;
    newValues.liquidation_compensation_rate = +newValues.liquidation_compensation_rate;
    newValues.liquidation_token_decimal = +newValues.liquidation_token_decimal;
    newValues.allow_list_countries_choosen = checkedCitizens;
    newValues.allow_list_nationality_choosen = checkedNationalities;
    onUpdate(newValues);
  };

  const renderCheckbox = (checkbox, setCheckbox) => (
    <>
      {Object.keys(checkbox).map((name, key) => (
        <label
          className="checkbox"
          key={key}
        >
          <input
            type="checkbox"
            className="checkbox__input"
            checked={checkbox[name]}
            disabled={!editingAllowed}
            onChange={onChangeCheckbox(name, checkbox, setCheckbox)}
            value={checkbox[name]}
          />
          <div className="checkbox__inner">
            <div className="checkbox__tick">{' '}</div>
            <div className="checkbox__text">{`Accept ${nameFieldApplication[name]}`}</div>
          </div>
        </label>
      ))}
    </>
  );

  const onDropFile = (name) => (img, imgURI) => {
    let newImage = {}
    if (img.length > 0 && imgURI.length > 0) {
      newImage = {
        img: img[0],
        imgURI: imgURI[0],
      }
    }

    setValues({ ...values, [name]: newImage })
  }

  const renderTokenSettings = () => (
    <>
      <div className="settings__item">
        <div className="title-primary settings__title">{ t('Token Settings') }</div>

        <div className="settings__fieldset">
          <div className="settings__row flex__row flex__wrap">
            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="token_name"
              label="Token Name"
              state={values}
              setState={setValues}
              error={error}
              disabled={!editingAllowed}
              loading={loading}
            />

            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="token_symbol"
              label="Token Symbol"
              state={values}
              setState={setValues}
              error={error}
              disabled={!editingAllowed}
              loading={loading}
            />
          </div>

          <div className="field">
            <div className="field__label">
              { t('Token type') }
            </div>

            <div className="field__wrap d__flex flex__row flex__wrap">
              <RadioTheme
                disabled={!editingAllowed}
                isGrayText
                isRadioGroupHorizontal
                value={TOKEN_TYPES.EQUITY}
                radioText={t('Equity')}
                nameGroup="tokenType"
                defaultChecked={values.token_type === TOKEN_TYPES.EQUITY}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, token_type: value })
                }
              />

              <RadioTheme
                disabled={!editingAllowed}
                isGrayText
                isRadioGroupHorizontal
                value={TOKEN_TYPES.BOND}
                radioText={t('Bond')}
                nameGroup="tokenType"
                defaultChecked={values.token_type === TOKEN_TYPES.BOND}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, token_type: value })
                }
              />

              <RadioTheme
                disabled={!editingAllowed}
                isGrayText
                isRadioGroupHorizontal
                value={TOKEN_TYPES.UTILITY}
                radioText={t('Utility')}
                nameGroup="tokenType"
                defaultChecked={values.token_type === TOKEN_TYPES.UTILITY}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, token_type: value })
                }
              />

              <RadioTheme
                disabled={!editingAllowed}
                isGrayText
                isRadioGroupHorizontal
                value={TOKEN_TYPES.NONE}
                radioText={t('None')}
                nameGroup="tokenType"
                defaultChecked={values.token_type === TOKEN_TYPES.NONE}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, token_type: value })
                }
              />

              <RadioTheme
                disabled={!editingAllowed}
                isGrayText
                isRadioGroupHorizontal
                value={TOKEN_TYPES.LIQUIDATION}
                radioText={t('Liquidation')}
                nameGroup="tokenType"
                defaultChecked={values.token_type === TOKEN_TYPES.LIQUIDATION}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, token_type: value })
                }
              />
            </div>
          </div>

          {(values || {}).token_type === TOKEN_TYPES.NONE && (
            <>
              <SwitchCheckbox
                defaultChecked={(values || {}).rename_share_to_token}
                onChange={({ target: { checked } }) =>
                  setValues({ ...values, rename_share_to_token: checked })
                }
                value="rename_share_to_token"
                switchText={t('Rename Shares to Tokens in Warning Disclaimer')}
              />
              <div className="space space__16">{' '}</div>
            </>
          )}

          {(values || {}).token_type === TOKEN_TYPES.LIQUIDATION && (
            <>
              <div className="settings__row flex__row flex__wrap">
                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="liquidation_contract_address"
                  label="Contract Address"
                  state={values}
                  setState={(newState) => {
                    setValues(newState)
                    fetchDecimal(newState.liquidation_contract_address).then(decimal => {
                      if (decimal) {
                        setValues({
                          ...newState,
                          liquidation_token_decimal: decimal
                        })
                      }
                    })
                  }}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                />

                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="liquidation_token_decimal"
                  label="Token decimal"
                  state={values}
                  setState={setValues}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                  type="number"
                />
              </div>

              <div className="settings__row flex__row flex__wrap">
                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="liquidation_holder_addresses"
                  label="Holder addresses"
                  state={values}
                  setState={setValues}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                  type="textarea"
                  helperText="1 address/line"
                />

                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="liquidation_compensation_rate"
                  label="Compensation Rate (in USDC)"
                  state={values}
                  setState={setValues}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                  type="number"
                />

                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="liquidation_specific_compensation_rate"
                  label="Specific Compensation Rate (in USDC)"
                  state={values}
                  setState={setValues}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                  type="textarea"
                />
              </div>
            </>
          )}

          <div className="field">
            <div className="field__label">
              { t('Prospectus date, issuing guidelines or sale agreement') }
            </div>

            <div className="field__wrap d__flex flex__row flex__wrap">
              <RadioTheme
                nameGroup="guidelinesAgreementType"
                disabled={!editingAllowed}
                isGrayText
                isRadioGroupHorizontal
                radioText={t("Prospectus date")}
                value={PROSPECTUS_OR_ISSUING.PROSPECTUS}
                defaultChecked={values.prospectus_or_issuing === PROSPECTUS_OR_ISSUING.PROSPECTUS}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, prospectus_or_issuing: value })
                }
              />

              <RadioTheme
                nameGroup="guidelinesAgreementType"
                disabled={!editingAllowed}
                isGrayText
                isRadioGroupHorizontal
                radioText={t("Issuing guidelines")}
                value={PROSPECTUS_OR_ISSUING.ISSUING}
                defaultChecked={values.prospectus_or_issuing === PROSPECTUS_OR_ISSUING.ISSUING}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, prospectus_or_issuing: value })
                }
              />

              <RadioTheme
                nameGroup="guidelinesAgreementType"
                disabled={!editingAllowed}
                isGrayText
                isRadioGroupHorizontal
                radioText={t("Token sale agreement")}
                value={PROSPECTUS_OR_ISSUING.TOKEN_SALE_AGREEMENT}
                defaultChecked={values.prospectus_or_issuing === PROSPECTUS_OR_ISSUING.TOKEN_SALE_AGREEMENT}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, prospectus_or_issuing: value })
                }
              />

              <RadioTheme
                nameGroup="guidelinesAgreementType"
                disabled={!editingAllowed}
                isGrayText
                isRadioGroupHorizontal
                radioText={t("Token Offering Memorandum")}
                value={PROSPECTUS_OR_ISSUING.TOKEN_OFFERING_MEMORANDUM}
                defaultChecked={values.prospectus_or_issuing === PROSPECTUS_OR_ISSUING.TOKEN_OFFERING_MEMORANDUM}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, prospectus_or_issuing: value })
                }
              />

              <RadioTheme
                nameGroup="guidelinesAgreementType"
                disabled={!editingAllowed}
                isGrayText
                isRadioGroupHorizontal
                radioText={t("Token Safe")}
                value={PROSPECTUS_OR_ISSUING.SAFE}
                defaultChecked={values.prospectus_or_issuing === PROSPECTUS_OR_ISSUING.SAFE}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, prospectus_or_issuing: value })
                }
              />
            </div>
          </div>

          {(values || {}).prospectus_or_issuing ===
          PROSPECTUS_OR_ISSUING.ISSUING && (
            <div className="settings__row flex__row flex__wrap">
              <div className="field">
                <div className="field__label">
                  {t('Issuing guidelines date')}
                </div>

                <div className="field__wrap field__wrap-date field__wrap-date-nomaterial">
                  <Input
                    propertyName="issuing_guidelines_date"
                    type="date"
                    state={values}
                    setState={setValues}
                    error={error}
                    disabled={!editingAllowed}
                    loading={loading}
                  />
                </div>
              </div>
              <InputTheme
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"
                propertyName="issuing_guidelines"
                label="Issuing guidelines"
                state={values}
                setState={setValues}
                error={error}
                disabled={!editingAllowed}
                loading={loading}
              />
            </div>
          )}

          {(values || {}).prospectus_or_issuing ===
          PROSPECTUS_OR_ISSUING.PROSPECTUS && (
            <div className="settings__row flex__row flex__wrap">
              <div className="field">
                <div className="field__label">
                  {t('Prospectus date')}
                </div>

                <div className="field__wrap field__wrap-date field__wrap-date-nomaterial">
                  <Input
                    propertyName="prospectus_date"
                    type="date"
                    state={values}
                    setState={setValues}
                    error={error}
                    disabled={!editingAllowed}
                    loading={loading}
                  />
                </div>
              </div>

              <InputTheme
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"
                propertyName="prospectus_url"
                label="Prospectus URL"
                state={values}
                setState={setValues}
                error={error}
                disabled={!editingAllowed}
                loading={loading}
              />
            </div>
          )}

          {(values || {}).prospectus_or_issuing ===
          PROSPECTUS_OR_ISSUING.TOKEN_SALE_AGREEMENT && (
            <div className="settings__row flex__row flex__wrap">
              <div className="field">
                <div className="field__label">
                  {t('Token sale agreement date')}
                </div>

                <div className="field__wrap field__wrap-date field__wrap-date-nomaterial">
                  <Input
                    propertyName="token_sale_agreement_date"
                    type="date"
                    state={values}
                    setState={setValues}
                    error={error}
                    disabled={!editingAllowed}
                    loading={loading}
                  />
                </div>
              </div>

              <InputTheme
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"
                propertyName="token_sale_agreement_url"
                label="Token sale agreement URL"
                state={values}
                setState={setValues}
                error={error}
                disabled={!editingAllowed}
                loading={loading}
              />
            </div>
          )}

          {(values || {}).prospectus_or_issuing ===
          PROSPECTUS_OR_ISSUING.TOKEN_OFFERING_MEMORANDUM && (
            <div className="settings__row flex__row flex__wrap">
              <div className="field">
                <div className="field__label">
                  {t('Token offering memorandum date')}
                </div>

                <div className="field__wrap field__wrap-date field__wrap-date-nomaterial">
                  <Input
                    propertyName="memorandum_date"
                    type="date"
                    state={values}
                    setState={setValues}
                    error={error}
                    disabled={!editingAllowed}
                    loading={loading}
                  />
                </div>
              </div>

              <InputTheme
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"
                propertyName="memorandum_url"
                label="Memorandum URL"
                state={values}
                setState={setValues}
                error={error}
                disabled={!editingAllowed}
                loading={loading}
              />
            </div>
          )}

          {(values || {}).prospectus_or_issuing ===
          PROSPECTUS_OR_ISSUING.SAFE && (
            <div className="settings__row flex__row flex__wrap">
              <div className="field">
                <div className="field__label">
                  {t('Token Safe date')}
                </div>

                <div className="field__wrap field__wrap-date field__wrap-date-nomaterial">
                  <Input
                    propertyName="token_safe_date"
                    type="date"
                    state={values}
                    setState={setValues}
                    error={error}
                    disabled={!editingAllowed}
                    loading={loading}
                  />
                </div>
              </div>

              <InputTheme
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"
                propertyName="token_safe_url"
                label="Token Safe URL"
                state={values}
                setState={setValues}
                error={error}
                disabled={!editingAllowed}
                loading={loading}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );

  const renderCurrencySettings = () => (
    <div className="settings__item">
      <div className="title-primary settings__title">{ t('Currency Settings') }</div>

      <div className="settings__fieldset">
        <div className="settings__row flex__row flex__wrap">
          <InputTheme
            classWrapper="field"
            classLabel="field__label"
            classInput="field__input"
            type="number"
            label="Min investment amount (token)"
            propertyName="minimum_investment_amount"
            state={values}
            setState={setValues}
            error={error}
            disabled={!editingAllowed}
            loading={loading}
          />

          <InputTheme
            classWrapper="field"
            classLabel="field__label"
            classInput="field__input"
            type="number"
            label="Max investment amount (token)"
            propertyName="maximum_investment_amount"
            state={values}
            setState={setValues}
            error={error}
            disabled={!editingAllowed}
            loading={loading}
          />

          <DropdownMaterial
            label={ t('Base currency') }
            propertyName="base_currency"
            state={values}
            setState={setValues}
            error={error}
            disabled={!editingAllowed}
            loading={loading}
            select
          >
            {ACCEPTED_CURRENCIES.map((item) => (
              <MenuItem key={item} value={item}>
                {item.toUpperCase()}
              </MenuItem>
            ))}
          </DropdownMaterial>
        </div>

        <div className="field field__radio-kyc-style-1 field__radio-kyc-style-1_1">
          <div className="field__wrap d__flex flex__row flex__wrap">
            {Object.keys(acceptedCurrencies).map((name, key) => (
              <label key={key} className="checkbox">
                <input
                  type="checkbox"
                  className="checkbox__input"
                  checked={acceptedCurrencies[name]}
                  disabled={!editingAllowed}
                  onChange={onChangeCheckbox(
                    name,
                    acceptedCurrencies,
                    setAcceptedCurrencies
                  )}
                  value={name}
                />

                <div className="checkbox__inner">
                  <div className="checkbox__tick">{' '}</div>
                  <div className="checkbox__text">{`Accept ${name}`}</div>
                </div>
              </label>
            ))}
          </div>
        </div>

        <InputTheme
          classWrapper="field"
          classLabel="field__label"
          classInput="field__input"
          label="KYC Text"
          propertyName="kyc_text"
          state={values}
          setState={setValues}
          error={error}
          disabled={!editingAllowed}
          loading={loading}
        />

        <label
          className="checkbox"
        >
          <input
            type="checkbox"
            className="checkbox__input"
            checked={values.enforce_token_minimum_at_kyc}
            disabled={!editingAllowed}
            onChange={onChangeCheckbox("enforce_token_minimum_at_kyc", values, setValues)}
            color="primary"
            value={values.enforce_token_minimum_at_kyc}
          />

          <div className="checkbox__inner">
            <div className="checkbox__tick">{' '}</div>
            <div className="checkbox__text">
              { t('Enforce token minimum at KYC') }
            </div>
          </div>
        </label>
      </div>
    </div>
  );

  const renderVideoIdentValueSettings = () => (
    <div className="settings__item">
      <div className="title-primary settings__title">{ t('Video Ident Settings') }</div>

      <div className="settings__fieldset">
        <InputTheme
          classWrapper="field"
          classLabel="field__label"
          classInput="field__input"
          propertyName="video_ident_value"
          label="Liveness check value (CHF)"
          state={values}
          type="number"
          setState={setValues}
          error={error}
          disabled={!editingAllowed}
          loading={loading}
        />
      </div>
    </div>
  );

  const renderGoogleAnalyticsSettings = () => (
    <div className="settings__item">
      <div className="title-primary settings__title">{ t('Analytics Settings') }</div>

      <div className="settings__fieldset">
        <div className="settings__row flex__row flex__wrap">
          <InputTheme
            classWrapper="field"
            classLabel="field__label"
            classInput="field__input"
            propertyName="google_analytics_id"
            label={
              <>
                Google analytics id
                {isFeatureEnabled('GOOGLE_ANALYTICS', availableFeatures) || <LockedFeatureIcon />}
              </>
            }
            state={values}
            setState={setValues}
            error={error}
            disabled={!editingAllowed || !isFeatureEnabled('GOOGLE_ANALYTICS', availableFeatures)}
            loading={loading}
          />

          <InputTheme
            classWrapper="field"
            classLabel="field__label"
            classInput="field__input"
            propertyName="google_tag_manager_id"
            label={
              <>
                Google tag manager id
                {isFeatureEnabled('GOOGLE_TAG_MANAGER', availableFeatures) || <LockedFeatureIcon />}
              </>
            }
            state={values}
            setState={setValues}
            error={error}
            disabled={!editingAllowed || !isFeatureEnabled('GOOGLE_TAG_MANAGER', availableFeatures)}
            loading={loading}
          />
        </div>

        <InputTheme
          classWrapper="field"
          classLabel="field__label"
          classInput="field__input"
          propertyName="pixel_id"
          label={
            <>
              Facebook pixel id
              {isFeatureEnabled('FACEBOOK_PIXEL', availableFeatures) || <LockedFeatureIcon />}
            </>
          }
          state={values}
          setState={setValues}
          error={error}
          disabled={!editingAllowed || !isFeatureEnabled('FACEBOOK_PIXEL', availableFeatures)}
          loading={loading}
        />
      </div>
    </div>
  );

  const UploadPreview = ({ path, ...rest }) => {
    if (getOriginalFileExtension(path) === 'pdf') {
      return <div className="product__preview"><embed src={getFileUrl(path)} width="500" height="375" type="application/pdf" {...rest} /></div>
    }

    return <div className="product__preview"><img alt="" src={getFileUrl(path)} {...rest} /></div>
  }

  const renderFieldLoginPageSettings = () => {
    if (!isFeatureEnabled('YOUTUBE_LOGIN_PAGE', availableFeatures)) {
      return (
        <div className="settings__item">
          <div className="title-red settings__title">
            {t('Login page')}
            <LockedFeatureIcon />
          </div>
        </div>
      );
    }
    return (
      <div className="settings__item">
        <div className="title-primary settings__title">{ t('Login page') }</div>

        <div className="settings__fieldset">
          <InputTheme
            classWrapper="field"
            classLabel="field__label"
            classInput="field__input"
            propertyName="login_youtube_video"
            label="Youtube video"
            state={values}
            setState={setValues}
            error={error}
            disabled={!editingAllowed}
            loading={loading}
            helperText="video url format = https://www.youtube.com/embed/{video code}"
          />

          <InputTheme
            classWrapper="field"
            classLabel="field__label"
            classInput="field__input"
            propertyName="login_youtube_text"
            label="Youtube video text"
            state={values}
            setState={setValues}
            error={error}
            disabled={!editingAllowed}
            loading={loading}
          />

          {settings.thumbnail_path && (
            <div className="field field-current_thumbnail">
              <div className="field__label">
                { t('Current thumbnail') }
              </div>

              <div className="field__wrapper">
                <UploadPreview path={settings.thumbnail_path} alt="Thumbnail" />
              </div>
            </div>
          )}

          {editingAllowed && (
            <div className="field field-upload">
              <ImageUpload
                buttonText="Select thumbnail"
                onChange={onDropFile('thumbnail')}
                maxFileSize={1048576}
                withPreview
                singleImagePick
                singleFile
                defaultImages={
                  (values.thumbnail || {}).imgURI
                    ? [values.thumbnail]
                    : []
                }
              >
                <div className="file__wrap_text text__center">
                  { t('New Thumbnail') }
                  <br />
                  { t('Drag and drop image') }
                </div>
              </ImageUpload>
            </div>
          )}

          <div className="settings__row flex__row flex__wrap have__fields">
            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="first_link_login"
              label="First link button"
              state={values}
              setState={setValues}
              error={error}
              disabled={!editingAllowed}
              loading={loading}
            />

            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="first_text_login"
              label="First text button"
              state={values}
              setState={setValues}
              error={error}
              disabled={!editingAllowed}
              loading={loading}
            />

            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="second_link_login"
              label="First link button"
              state={values}
              setState={setValues}
              error={error}
              disabled={!editingAllowed}
              loading={loading}
            />

            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="second_text_login"
              label="First text button"
              state={values}
              setState={setValues}
              error={error}
              disabled={!editingAllowed}
              loading={loading}
            />
          </div>
        </div>
      </div>
    )
  };

  const renderFieldsSendEmail = () => {
    if (!isFeatureEnabled('AUTO_FOLLOW_UP', availableFeatures)) {
      return (
        <div className="settings__item">
          <div className="title-primary settings__title">
            {t('Send email')}
            <LockedFeatureIcon />
          </div>
        </div>
      );
    }

    return (
      <div className="settings__item">
        <div className="title-primary settings__title">{t('Send email')}</div>

        <div className="settings__fieldset">
          <div className="settings__row flex__row flex__wrap">
            {Object.keys(daysForNotifications).map((name, key) => (
              <InputTheme
                key={key}
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"
                type="number"
                propertyName={fieldsDaysForNotifications[name].propertyName}
                label={fieldsDaysForNotifications[name].label}
                state={daysForNotifications}
                setState={onChangeInput(
                  name,
                  daysForNotifications,
                  setDaysForNotifications
                )}
                error={error}
                disabled={!editingAllowed}
                loading={loading}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }

  const renderOtherFields = () => (
    <div className="settings__item">
      <div className="title-primary settings__title">{ t('Other settings') }</div>

      <div className="settings__fieldset">
        <div className="d__flex flex__wrap flex__row field field__radio-kyc-style-1 field__radio-kyc__style-1_2">
          <label
            className="checkbox"
          >
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.show_source_signup}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox(
                "show_source_signup",
                values,
                setValues
              )}
              value={values.show_source_signup}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">{ t('Show source registered') }</div>
            </div>
          </label>

          {renderCheckbox(acceptMandatoryKYC, setAcceptMandatoryKYC)}

          <label
            className="checkbox"
          >
            <input
              type="checkbox"
              className="checkbox__input"
              value={values.send_gto_api_when_changing_status}
              checked={values.send_gto_api_when_changing_status}
              disabled={!editingAllowed || !isFeatureEnabled('SALES_API', availableFeatures)}
              onChange={onChangeCheckbox(
                "send_gto_api_when_changing_status",
                values,
                setValues
              )}
            />

            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('Send info about transactions to ReferralSource endpoint') }
                {isFeatureEnabled('SALES_API', availableFeatures) || (<LockedFeatureIcon />)}
              </div>
            </div>
          </label>

          <label
            className="checkbox"
          >
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.show_verify_wallet}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox(
                "show_verify_wallet",
                values,
                setValues
              )}
              value={values.show_verify_wallet}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">{ t('Show Verify Wallet Button') }</div>
            </div>
          </label>

          { /* KYC Customization */ }
          <label
            className="checkbox"
          >
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyc_enable_kyb}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox("kyc_enable_kyb", values, setValues)}
              color="primary"
              value={values.kyc_enable_kyb}
            />

            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('Enable KYB registration') }
              </div>
            </div>
          </label>

          <label
            className="checkbox"
          >
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.allow_sync_kyc_data_investhub}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox("allow_sync_kyc_data_investhub", values, setValues)}
              color="primary"
              value={values.allow_sync_kyc_data_investhub}
            />

            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('Allow Sync Data to Investhub') }
              </div>
            </div>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.add_ether_wallet_later_option}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox(
                "add_ether_wallet_later_option",
                values,
                setValues
              )}
              value={values.add_ether_wallet_later_option}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('Add ether wallet later') }
              </div>
            </div>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.require_exact_investment_amount_on_none}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox(
                "require_exact_investment_amount_on_none",
                values,
                setValues
              )}
              value={values.require_exact_investment_amount_on_none}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('Require exact amount of investment on Token None') }
              </div>
            </div>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.disable_erc_20_check}
              disabled={!editingAllowed || !isFeatureEnabled('ALLOW_NON_ERC20', availableFeatures)}
              onChange={onChangeCheckbox(
                "disable_erc_20_check",
                values,
                setValues
              )}
              value={values.disable_erc_20_check}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('Disable ERC-20 check for receiving wallet') }
                {isFeatureEnabled('ALLOW_NON_ERC20', availableFeatures) || (<LockedFeatureIcon />)}
              </div>
            </div>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyc_require_referral}
              disabled={!editingAllowed || !isFeatureEnabled('REFERRAL_CODE', availableFeatures)}
              onChange={onChangeCheckbox(
                "kyc_require_referral",
                values,
                setValues
              )}
              value={values.kyc_require_referral}
            />

            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('Require referral code for KYC') }
                {isFeatureEnabled('REFERRAL_CODE', availableFeatures) || (<LockedFeatureIcon />)}
              </div>
            </div>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.hide_wallet_format_note}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox(
                "hide_wallet_format_note",
                values,
                setValues
              )}
              value={values.hide_wallet_format_note}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('Hide wallet format note') }
              </div>
            </div>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyc_show_receiving_wallet}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox(
                "kyc_show_receiving_wallet",
                values,
                setValues
              )}
              value={values.kyc_show_receiving_wallet}
            />

            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('Show receiving wallet field') }
              </div>
            </div>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyc_show_website}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox(
                "kyc_show_website",
                values,
                setValues
              )}
              value={values.kyc_show_website}
            />

            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('Show website field') }
              </div>
            </div>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyc_show_position}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox(
                "kyc_show_position",
                values,
                setValues
              )}
              value={values.kyc_show_position}
            />

            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('Show position field') }
              </div>
            </div>
          </label>

          <label
            className="checkbox"
          >
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyc_occupation}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox("kyc_occupation", values, setValues)}
              value={values.kyc_occupation}
            />

            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('Occupation Options on KYC') }
              </div>
            </div>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyc_yearly_salary}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox(
                "kyc_yearly_salary",
                values,
                setValues
              )}
              value={values.kyc_yearly_salary}
            />

            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('Yearly Salary Options on KYC') }
              </div>
            </div>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyc_employer_company_name}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox(
                "kyc_employer_company_name",
                values,
                setValues
              )}
              value={values.kyc_employer_company_name}
            />

            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('Employer Company Name on KYC') }
              </div>
            </div>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyc_education_level}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox(
                "kyc_education_level",
                values,
                setValues
              )}
              value={values.kyc_education_level}
            />

            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('Education Level on KYC') }
              </div>
            </div>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyc_phone_number}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox(
                "kyc_phone_number",
                values,
                setValues
              )}
              value={values.kyc_phone_number}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('KYC require phone number') }
              </div>
            </div>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyc_net_investable_assets}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox(
                "kyc_net_investable_assets",
                values,
                setValues
              )}
              value={values.kyc_net_investable_assets}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('KYC require total wealth') }
              </div>
            </div>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyc_what_will_you_use}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox(
                "kyc_what_will_you_use",
                values,
                setValues
              )}
              value={values.kyc_what_will_you_use}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('KYC require what will you use') }
              </div>
            </div>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyc_politically_exposed_persons}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox(
                "kyc_politically_exposed_persons",
                values,
                setValues
              )}
              value={values.kyc_politically_exposed_persons}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('KYC require politically exposed persons') }
              </div>
            </div>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyc_prefer_currency}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox(
                "kyc_prefer_currency",
                values,
                setValues
              )}
              value={values.kyc_prefer_currency}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('KYC require preferred payment currency') }
              </div>
            </div>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyc_intended_investment}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox(
                "kyc_intended_investment",
                values,
                setValues
              )}
              value={values.kyc_intended_investment}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('KYC require intended investment') }
              </div>
            </div>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyc_require_industry}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox(
                "kyc_require_industry",
                values,
                setValues
              )}
              value={values.kyc_require_industry}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('KYC require industry') }
              </div>
            </div>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyc_support_source_of_funds}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox(
                "kyc_support_source_of_funds",
                values,
                setValues
              )}
              value={values.kyc_support_source_of_funds}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('KYC require support document for source of funds') }
              </div>
            </div>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyc_support_source_of_wealth}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox(
                "kyc_support_source_of_wealth",
                values,
                setValues
              )}
              value={values.kyc_support_source_of_wealth}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('KYC require support document for source of wealth') }
              </div>
            </div>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyc_acknowledgement_of_receipt}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox(
                "kyc_acknowledgement_of_receipt",
                values,
                setValues
              )}
              value={values.kyc_acknowledgement_of_receipt}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('KYC require accept acknowledgement of receipt') }
              </div>
            </div>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyc_client_statement}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox(
                "kyc_client_statement",
                values,
                setValues
              )}
              value={values.kyc_client_statement}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('KYC require accept client statement') }
              </div>
            </div>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyc_confirmations}
                disabled={!editingAllowed}
                onChange={onChangeCheckbox(
                  "kyc_confirmations",
                  values,
                  setValues
                )}
                value={values.kyc_confirmations}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('KYC require accept confirmations') }
              </div>
            </div>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyc_require_receiving_wallet}
                disabled={!editingAllowed}
                onChange={onChangeCheckbox(
                  "kyc_require_receiving_wallet",
                  values,
                  setValues
                )}
                value={values.kyc_require_receiving_wallet}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('KYC require receiving wallet') }
              </div>
            </div>
          </label>

          <label
            className="checkbox"
          >
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.public_sale_sof_sow}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox("public_sale_sof_sow", values, setValues)}
              color="primary"
              value={values.public_sale_sof_sow}
            />

            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('Enable Public sale for SOF SOW dropdown') }
              </div>
            </div>
          </label>

          { /* KYC Management */ }
          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.allow_compliance_update_kyc}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox(
                "allow_compliance_update_kyc",
                values,
                setValues
              )}
              value={values.allow_compliance_update_kyc}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('Allow Compliance update KYC') }
              </div>
            </div>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.allow_duplicated_id_kyc}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox(
                "allow_duplicated_id_kyc",
                values,
                setValues
              )}
              value={values.allow_duplicated_id_kyc}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('Allow Duplicated ID KYC') }
              </div>
            </div>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.hide_token_balance}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox(
                "hide_token_balance",
                values,
                setValues
              )}
              value={values.hide_token_balance}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('Hide Token Balance') }
              </div>
            </div>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.show_investhub_dao_login_btn}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox(
                "show_investhub_dao_login_btn",
                values,
                setValues
              )}
              value={values.show_investhub_dao_login_btn}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('Show Investhub DAO Login Button') }
              </div>
            </div>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.show_tenant_on_investhub}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox(
                "show_tenant_on_investhub",
                values,
                setValues
              )}
              value={values.show_tenant_on_investhub}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('Show this site on InvestHub listing') }
              </div>
            </div>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.enable_sync_pythagoras}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox(
                "enable_sync_pythagoras",
                values,
                setValues
              )}
              value={values.enable_sync_pythagoras}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('Enable pythagoras csv sync') }
              </div>
            </div>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.force_user_till_first_transaction}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox(
                "force_user_till_first_transaction",
                values,
                setValues
              )}
              value={values.force_user_till_first_transaction}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('Navigate user to first transaction') }
              </div>
            </div>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.force_generate_wallet}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox(
                "force_generate_wallet",
                values,
                setValues
              )}
              value={values.force_generate_wallet}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('Force generate wallet') }
              </div>
            </div>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyc_require_legal_protection_insurance}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox(
                "kyc_require_legal_protection_insurance",
                values,
                setValues
              )}
              value={values.kyc_require_legal_protection_insurance}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">
                { t('Require KYC legal protection insurance') }
              </div>
            </div>
          </label>
        </div>

        <div className="d__flex flex__wrap flex__row field field__radio-kyc-style-1 field__radio-kyc__style-1_2">
          <label className="checkbox" >
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyb_require_activity_description}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox('kyb_require_activity_description', values, setValues)}
              value={values.kyb_require_activity_description}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">{ t('KYB require activity description') }</div>
            </div>
          </label>
          <label className="checkbox" >
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyb_require_main_income}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox('kyb_require_main_income', values, setValues)}
              value={values.kyb_require_main_income}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">{ t('KYB require main income') }</div>
            </div>
          </label>
          <label className="checkbox" >
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyb_require_other_income}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox('kyb_require_other_income', values, setValues)}
              value={values.kyb_require_other_income}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">{ t('KYB require other income') }</div>
            </div>
          </label>
          <label className="checkbox" >
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyb_require_gross_revenue}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox('kyb_require_gross_revenue', values, setValues)}
              value={values.kyb_require_gross_revenue}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">{ t('KYB require gross revenue') }</div>
            </div>
          </label>
          <label className="checkbox" >
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyb_require_organization_chart}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox('kyb_require_organization_chart', values, setValues)}
              value={values.kyb_require_organization_chart}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">{ t('KYB require organization chart') }</div>
            </div>
          </label>
          <label className="checkbox" >
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyb_require_association_article}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox('kyb_require_association_article', values, setValues)}
              value={values.kyb_require_association_article}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">{ t('KYB require association article') }</div>
            </div>
          </label>
          <label className="checkbox" >
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyb_require_last_balance_sheet}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox('kyb_require_last_balance_sheet', values, setValues)}
              value={values.kyb_require_last_balance_sheet}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">{ t('KYB require last balance sheet') }</div>
            </div>
          </label>
          <label className="checkbox" >
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyb_show_good_standing_certificate}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox('kyb_show_good_standing_certificate', values, setValues)}
              value={values.kyb_show_good_standing_certificate}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">{ t('KYB show good standing certificate') }</div>
            </div>
          </label>
          <label className="checkbox" >
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyb_require_shareholder_list}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox('kyb_require_shareholder_list', values, setValues)}
              value={values.kyb_require_shareholder_list}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">{ t('KYB require shareholder list') }</div>
            </div>
          </label>
          <label className="checkbox" >
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyb_require_ubo}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox('kyb_require_ubo', values, setValues)}
              value={values.kyb_require_ubo}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">{ t('KYB require ubo') }</div>
            </div>
          </label>
          <label className="checkbox" >
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyb_require_director_form}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox('kyb_require_director_form', values, setValues)}
              value={values.kyb_require_director_form}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">{ t('KYB require identification of the beneficial owner') }</div>
            </div>
          </label>
          <label className="checkbox" >
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyb_require_utility_bill}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox('kyb_require_utility_bill', values, setValues)}
              value={values.kyb_require_utility_bill}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">{ t('KYB require utility bill') }</div>
            </div>
          </label>

          <label className="checkbox" >
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyb_show_business_countries}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox('kyb_show_business_countries', values, setValues)}
              value={values.kyb_show_business_countries}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">{ t('KYB show Business in any of these Countries question') }</div>
            </div>
          </label>

          <label className="checkbox" >
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyb_show_business_following_industries}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox('kyb_show_business_following_industries', values, setValues)}
              value={values.kyb_show_business_following_industries}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">{ t('KYB show Business operated in the following industries question') }</div>
            </div>
          </label>

          <label className="checkbox" >
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyb_skip_confirm_beneficial_ownership}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox('kyb_skip_confirm_beneficial_ownership', values, setValues)}
              value={values.kyb_skip_confirm_beneficial_ownership}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">{ t('KYB optional confirm of the beneficial ownership') }</div>
            </div>
          </label>
        </div>
        <div className="d__flex flex__wrap flex__row field field__radio-kyc-style-1 field__radio-kyc__style-1_2">
          <label className="checkbox" >
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.kyc_skip_legal_disclaimer}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox('kyc_skip_legal_disclaimer', values, setValues)}
              value={values.kyc_skip_legal_disclaimer}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">{ t('Skip KYC legal disclaimer') }</div>
            </div>
          </label>
        </div>

        <div className="d__flex flex__wrap flex__row field field__radio-kyc-style-1 field__radio-kyc__style-1_2">
          <label className="checkbox" >
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.use_registration_confirm_for_proof_of_residence}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox('use_registration_confirm_for_proof_of_residence', values, setValues)}
              value={values.use_registration_confirm_for_proof_of_residence}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">{ t('Use Registration confirmation for proof of residence at last KYC level') }</div>
            </div>
          </label>
        </div>

        <div className="d__flex flex__wrap flex__row field field__radio-kyc-style-1 field__radio-kyc__style-1_2">
          <label className="checkbox" >
            <input
              type="checkbox"
              className="checkbox__input"
              checked={values.enable_proof_of_residence_checking}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox('enable_proof_of_residence_checking', values, setValues)}
              value={values.enable_proof_of_residence_checking}
            />
            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
              <div className="checkbox__text">{ t('Enable proof of residence checking') }</div>
            </div>
          </label>
        </div>

        <InputTheme
          classWrapper="field"
          classLabel="field__label"
          classInput="field__input"
          propertyName="interval_upload_csv_pythagoras"
          label="Csv (pythagoras) upload  Interval in days"
          state={values}
          setState={onChangeInput(
            "interval_upload_csv_pythagoras",
            values,
            setValues
          )}
          error={error}
          type="number"
          disabled={!editingAllowed}
          loading={loading}
          helperText={
            <>
              <p>{ t('Bucket equanimity-sync') }</p>
              {!loadingLastUploadCsv && !errorLastUploadCsv && dateUploadCopyCsv && (
                <p>
                  { t('Last upload') }{" "}
                  {moment(dateUploadCopyCsv).format("YYYY-MM-DD hh:mm:ss a")}
                </p>
              )}
            </>
          }
        />

        <div className="field create__line">
          <div className="create__info">
            { t('Enable transaction limit') }
          </div>
          <label className="switch">
            <input
              type="checkbox"
              className="switch__input"
              checked={values.enable_transaction_limit}
              disabled={!editingAllowed}
              onChange={onChangeCheckbox(
                "enable_transaction_limit",
                values,
                setValues
              )}
              value={values.enable_transaction_limit}
            />

            <div className="switch__inner">
              <div className="switch__box">{' '}</div>
            </div>
          </label>
        </div>

        <InputTheme
          classWrapper="field"
          classLabel="field__label"
          classInput="field__input"
          propertyName="transaction_limit_quota"
          label="Number of transaction a user can place"
          state={values}
          setState={onChangeInput(
            "transaction_limit_quota",
            values,
            setValues
          )}
          error={error}
          type="number"
          disabled={!editingAllowed}
          loading={loading}
          helperText={extractMsgFromErrByPropertyName()}
        />

        <InputTheme
          classWrapper="field"
          classLabel="field__label"
          classInput="field__input"
          propertyName="capital_increase_nominal_value"
          label="Capital increase Nominal value"
          state={values}
          setState={onChangeInput(
            "capital_increase_nominal_value",
            values,
            setValues
          )}
          error={error}
          type="number"
          disabled={!editingAllowed}
          loading={loading}
          helperText={extractMsgFromErrByPropertyName()}
        />
      </div>
    </div>
  );

  const renderWalletServices = () => (
    <div className="settings__item">
      <div className="title-primary settings__title">{t('Wallet Services')}</div>

      <div className="settings__fieldset">
        <DropdownMaterial
          label={ t('Wallet Services') }
          propertyName="wallet_service"
          state={values}
          setState={setValues}
          error={error}
          disabled={!editingAllowed}
          loading={loading}
          select
        >
          <MenuItem value="" />
          <MenuItem value="sendwyre" disabled={!isFeatureEnabled('SENDWYRE', availableFeatures)}>
            {isFeatureEnabled('SENDWYRE', availableFeatures) || <LockedFeatureIcon />}
            {' '}
            SENDWYRE
          </MenuItem>
          <MenuItem value="wallet_engine" disabled={!isFeatureEnabled('WALLET_ENGINE', availableFeatures)}>
              {isFeatureEnabled('WALLET_ENGINE', availableFeatures) || <LockedFeatureIcon />}
              {' '}
              WALLET_ENGINE
          </MenuItem>
        </DropdownMaterial>
      </div>
    </div>
  );

  const setSendwyrePaymentType = (value) => {
    setValues({ ...values, sendwyre_payment_type: value })
  }

  const renderSendwyreApiSettings = () => {
    if (!isFeatureEnabled('SENDWYRE', availableFeatures)) {
      return (
        <div className="settings__item">
          <div className="title-primary settings__title ">
            {t('Sendwyre API Settings')}
            <LockedFeatureIcon />
          </div>
        </div>
      )
    }
    return (
      <div className="settings__item">
        <div className="title-primary settings__title ">
          {t('Sendwyre API Settings')}
        </div>

        <div className="settings__row flex__row flex__wrap">
          <InputTheme
            classWrapper="field"
            classLabel="field__label"
            classInput="field__input"
            propertyName="sendwyre_api_key"
            type="text"
            label="Sendwyre API Key"
            state={values}
            setState={setValues}
            error={error}
            disabled={!editingAllowed}
            loading={loading}
          />
          <InputTheme
            classWrapper="field"
            classLabel="field__label"
            classInput="field__input"
            propertyName="sendwyre_api_secret"
            type="text"
            label="Sendwyre API Secret"
            state={values}
            setState={setValues}
            error={error}
            disabled={!editingAllowed}
            loading={loading}
          />
          <InputTheme
            classWrapper="field"
            classLabel="field__label"
            classInput="field__input"
            propertyName="sendwyre_account_id"
            type="text"
            label="Sendwyre Account ID"
            state={values}
            setState={setValues}
            error={error}
            disabled={!editingAllowed}
            loading={loading}
          />
        <div className="settings__fieldset field__left-padding">
          <div className="field-radio__material">
            <div className="field__label">{ t('Sendwyre Payment Types') }</div>

            <div className="field__wrap d__flex flex__row flex__wrap">
              <RadioTheme
                disabled={!editingAllowed}
                isGrayText
                isRadioGroupHorizontal
                value="enable_wallet"
                radioText={t(sendwyrePaymentTypes.enable_wallet)}
                defaultChecked={(values || {}).sendwyre_payment_type === 'enable_wallet'}
                onChange={({ target: { value } }) => setSendwyrePaymentType(value)}
              />
              <RadioTheme
                disabled={!editingAllowed}
                isGrayText
                isRadioGroupHorizontal
                value="enable_direct_purchase"
                radioText={t(sendwyrePaymentTypes.enable_direct_purchase)}
                defaultChecked={(values || {}).sendwyre_payment_type === 'enable_direct_purchase'}
                onChange={({ target: { value } }) => setSendwyrePaymentType(value)}
              />
              <RadioTheme
                disabled={!editingAllowed || true} //Haven't implemented
                isGrayText
                isRadioGroupHorizontal
                value="enable_nft_smart_onramps"
                radioText={t(sendwyrePaymentTypes.enable_nft_smart_onramps)}
                defaultChecked={(values || {}).sendwyre_payment_type === 'enable_nft_smart_onramps'}
                onChange={({ target: { value } }) => setSendwyrePaymentType(value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }

  const renderHotjarSetting = () => {
    if (!isFeatureEnabled('HOTJAR_INTEGRATION', availableFeatures)) {
      return (
        <div className="settings__item">
          <div className="title-primary settings__title ">
            {t('Hotjar Setting')}
            <LockedFeatureIcon />
          </div>
        </div>
      );
    }
    return (
      <div className="settings__item">
        <div className="title-primary settings__title ">{ t('Hotjar Setting') }</div>

        <div className="settings__fieldset">
          <InputTheme
            classWrapper="field"
            classLabel="field__label"
            classInput="field__input"
            propertyName="hotjar_id"
            type="text"
            label="Hotjar id"
            state={values}
            setState={setValues}
            error={error}
            disabled={!editingAllowed}
            loading={loading}
          />
        </div>
      </div>
    )
  };

  const renderIntercomAppIdSetting = () => (
    <div className="settings__item">
      <div className="title-primary settings__title">{ t('Intercom AppId Setting') }</div>

      <div className="settings__fieldset">
        <InputTheme
          classWrapper="field"
          classLabel="field__label"
          classInput="field__input"
          propertyName="intercom_app_id"
          type="text"
          state={values}
          label="AppId"
          setState={setValues}
          error={error}
          disabled={!editingAllowed}
          loading={loading}
        />
      </div>

      <div className="settings__fieldset">
        <div className="field field-radio__material">
          <div className="field__label">{ t('Region') }</div>

          <div className="field__wrap d__flex flex__row flex__wrap">
            <RadioTheme
              disabled={!editingAllowed}
              isGrayText
              isRadioGroupHorizontal
              value="US"
              radioText={t('US')}
              defaultChecked={(values || {}).intercom_region !== 'EU'}
              onChange={({ target: { value } }) =>
                setValues({ ...values, intercom_region: value })
              }
            />
            <RadioTheme
              disabled={!editingAllowed}
              isGrayText
              isRadioGroupHorizontal
              value="EU"
              radioText={t('EU')}
              defaultChecked={(values || {}).intercom_region === 'EU'}
              onChange={({ target: { value } }) =>
                setValues({ ...values, intercom_region: value })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );

  const renderInternalSalesApiSettings = () => {
    if (!isFeatureEnabled('SALES_API', availableFeatures)) {
      return (
        <div className="settings__item">
          <div className="title-primary settings__title">
            {t('Internal Sales API Settings')}
            <LockedFeatureIcon />
          </div>
        </div>
      )
    }

    return (
      <div className="settings__item">
        <div className="title-primary settings__title">
          {t('Internal Sales API Settings')}
        </div>

        <div className="settings__fieldset">
          <div className="settings__row flex__row flex__wrap">
            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="internal_sales_api"
              type="text"
              label="API Url"
              state={values}
              setState={setValues}
              error={error}
              disabled={!editingAllowed}
              loading={loading}
            />

            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="internal_sales_api_key"
              type="text"
              label="API Key"
              state={values}
              setState={setValues}
              error={error}
              disabled={!editingAllowed}
              loading={loading}
            />
          </div>
        </div>
      </div>
    );
  }

  const renderKycReferralCodeOption = () => {
    return (
      <div className="settings__item">
        <div className="title-primary settings__title">
          {t('Referral Code')}
        </div>

        <div className="settings__fieldset">
          <div className="field create__line remove__line">
            <div className="create__info">
              { t('KYC Referral code') }
            </div>
            <label className="switch">
              <input
                type="checkbox"
                className="switch__input"
                checked={values.kyc_referal_code}
                disabled={!editingAllowed}
                onChange={onChangeCheckbox(
                  "kyc_referal_code",
                  values,
                  setValues
                )}
                value={values.kyc_referal_code}
              />

              <div className="switch__inner">
                <div className="switch__box">{' '}</div>
              </div>
            </label>
          </div>
        </div>
      </div>
    )
  }

  const renderEnableDiscountLevelOption = () => {
    const isEnabled = isFeatureEnabled('DISCOUNT_LEVEL', availableFeatures)

    return (
      <div className="settings__item">
        <div className="title-primary settings__title">{ t('Discount Level Option') }</div>

        <div className="settings__fieldset">
          <SwitchCheckbox
            isRemoveLine
            defaultChecked={values.enable_kyc_discount_level}
            onChange={onChangeCheckbox(
              "enable_kyc_discount_level",
              values,
              setValues
            )}
            value={values.enable_kyc_discount_level}
            isDisabled={!editingAllowed || !isEnabled}
            switchText={
              <>
                { t('Enable KYC Discount Level') }
                {isEnabled || (<LockedFeatureIcon />)}
              </>
            }
          />

          {values.enable_kyc_discount_level && !isAwearetech && (
            <>
              <div className="space space__32">{' '}</div>
              <DropdownMaterial
                label={ t('KYC Discount phase') }
                propertyName="kyc_discount_phase"
                state={values}
                setState={setValues}
                error={error}
                disabled={!editingAllowed}
                loading={loading}
                select
              >
                {DISCOUNT_PHASE.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.label}
                  </MenuItem>
                ))}
              </DropdownMaterial>
            </>
          )}

          {values.enable_kyc_discount_level && isAwearetech && (
            <>
              <Box m={2}>
                <ul>
                  <li className="field__label">For investments between 10,000 USDC and 49,999.99 USDC, investors will receive a discount of 10%, making the price per AWEARE Token USD10.80.
                  </li>
                  <li className="field__label">For investments of 50,000 USDC or more, investors will receive a discount of 15%, making the price per AWEARE Token USD10.20.</li>
                  <li className="field__label">
                    The availability of these discounts will end based on one of the following conditions, to be determined as most suitable for the instrument:
                  </li>

                  <div className="settings__fieldset">
                    <div className="settings__row flex__row flex__wrap">
                      <InputTheme
                        classWrapper="field"
                        classLabel="field__label"
                        classInput="field__input"
                        propertyName="discount_maximum_raise"
                        type="number"
                        label="The discounts will end once a total USDC equivalent has been raised:"
                        state={values}
                        setState={setValues}
                        error={error}
                        disabled={!editingAllowed}
                        loading={loading}
                        formatData={(value) => +value}
                      />

                      <div className="field">
                        <div className="field__label">
                          The discounts will end at:
                        </div>

                        <div className="field__wrap field__wrap-date field__wrap-date-nomaterial">
                          <Input
                            propertyName="discount_end_date"
                            type="date"
                            state={values}
                            setState={setValues}
                            error={error}
                            disabled={!editingAllowed}
                            loading={loading}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                </ul>
              </Box>
            </>
          )}
        </div>
      </div>
    );
  };

  const renderPurchaseAgreementLinkSettings = () => {
    return (
      <div className="settings__item">
        <div className="title-primary settings__title">
          { t('Purchase agreement link') }
        </div>
        <div className="settings__fieldset">
          <InputTheme
            classWrapper="field"
            classLabel="field__label"
            classInput="field__input"
            propertyName="purchase_agreement_link"
            state={values}
            setState={setValues}
            error={error}
            disabled={!editingAllowed}
            loading={loading}
          />
        </div>
      </div>
    );
  };

  const renderSubscriptionAgreementLinkSettings = () => {
    return (
      <div className="settings__item">
        <div className="title-primary settings__title">{ t('Subscription Agreement') }</div>

        <div className="settings__fieldset">
          <SwitchCheckbox
            isRemoveLine
            defaultChecked={values.enable_subscription_agreement_link}
            onChange={onChangeCheckbox(
              "enable_subscription_agreement_link",
              values,
              setValues
            )}
            value={values.enable_subscription_agreement_link}
            isDisabled={!editingAllowed}
            switchText={
              <>
                { t('Enable Subscription Agreement') }
              </>
            }
          />

          {values.enable_subscription_agreement_link && (
            <div className="settings__fieldset">
              <InputTheme
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"
                propertyName="subscription_agreement_link"
                state={values}
                setState={setValues}
                error={error}
                disabled={!editingAllowed}
                loading={loading}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderStripeSetting = () => {
    if (!isFeatureEnabled('STRIPE_PAYMENT', availableFeatures)) {
      return (
        <div className="settings__item">
          <div className="title-primary settings__title">
            { t('Stripe Settings') }
            <LockedFeatureIcon />
          </div>
        </div>
      )
    }
    return (
      <div className="settings__item">
        <div className="title-primary settings__title">
          { t('Stripe Settings') }
        </div>

        <div className="settings__fieldset">
          <div className="field create__line">
            <div className="create__info">
              { t('Enable Stripe Payment') }
            </div>

            <label className="switch">
              <input
                type="checkbox"
                className="switch__input"
                checked={values.enable_stripe}
                disabled={!editingAllowed}
                onChange={onChangeCheckbox(
                  "enable_stripe",
                  values,
                  setValues
                )}
                value={values.enable_stripe}
              />

              <div className="switch__inner">
                <div className="switch__box">{' '}</div>
              </div>
            </label>
          </div>

          <div className="settings__row flex__row flex__wrap have__fields">
            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="stripe_public_key"
              label="Stripe Public Key"
              state={values}
              setState={setValues}
              error={error}
              disabled={!editingAllowed}
              loading={loading}
            />

            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="stripe_secret_key"
              label="Stripe Secret Key"
              state={values}
              setState={setValues}
              error={error}
              disabled={!editingAllowed}
              loading={loading}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderVoltIoSetting = () => {
    if (!isFeatureEnabled('VOLT_IO', availableFeatures)) {
      return (
        <div className="settings__item">
          <div className="title-primary settings__title">
            { t('VoltIo API Settings') }
            <LockedFeatureIcon />
          </div>
        </div>
      )
    }
    return (
      <div className="settings__item">
        <div className="title-primary settings__title">
          { t('VoltIo API Settings') }
        </div>

        <div className="settings__fieldset">
          <div className="field create__line">
            <div className="create__info">
              { t('Enable Volt.io Payment') }
            </div>

            <label className="switch">
              <input
                type="checkbox"
                className="switch__input"
                checked={values.enable_voltio}
                disabled={!editingAllowed}
                onChange={onChangeCheckbox(
                  "enable_voltio",
                  values,
                  setValues
                )}
                value={values.enable_voltio}
              />

              <div className="switch__inner">
                <div className="switch__box">{' '}</div>
              </div>
            </label>
          </div>

          <div className="settings__row flex__row flex__wrap have__fields">
            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="voltio_url"
              label="Volt.io URL"
              state={values}
              setState={setValues}
              error={error}
              disabled={!editingAllowed}
              loading={loading}
            />

            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="voltio_client_id"
              label="Volt.io Client ID"
              state={values}
              setState={setValues}
              error={error}
              disabled={!editingAllowed}
              loading={loading}
            />

            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="voltio_client_secret"
              label="Volt.io Client Secret"
              state={values}
              setState={setValues}
              error={error}
              disabled={!editingAllowed}
              loading={loading}
            />

            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="voltio_password"
              label="Volt.io Password"
              state={values}
              setState={setValues}
              error={error}
              disabled={!editingAllowed}
              loading={loading}
            />

            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="voltio_username"
              label="Volt.io Username"
              state={values}
              setState={setValues}
              error={error}
              disabled={!editingAllowed}
              loading={loading}
            />

            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="voltio_account"
              label="Volt.io Account"
              state={values}
              setState={setValues}
              error={error}
              disabled={!editingAllowed}
              loading={loading}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderMoneriumSetting = () => {
    return (
      <div className="settings__item">
        <div className="title-primary settings__title">
          { t('Monerium Settings') }
        </div>

        <div className="settings__fieldset">
          <div className="field create__line">
            <div className="create__info">
              { t('Enable Monerium') }
            </div>

            <label className="switch">
              <input
                type="checkbox"
                className="switch__input"
                checked={values.enable_monerium}
                disabled={!editingAllowed}
                onChange={onChangeCheckbox(
                  "enable_monerium",
                  values,
                  setValues
                )}
                value={values.enable_monerium}
              />

              <div className="switch__inner">
                <div className="switch__box">{' '}</div>
              </div>
            </label>
          </div>

          <div className="settings__row flex__row flex__wrap have__fields">
              <InputTheme
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"
                propertyName="monerium_client_id"
                label="Monerium Client ID"
                state={values}
                setState={setValues}
                error={error}
                disabled={!editingAllowed}
                loading={loading}
              />

              <InputTheme
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"
                propertyName="monerium_client_secret"
                label="Monerium Client Secret"
                state={values}
                setState={setValues}
                error={error}
                disabled={!editingAllowed}
                loading={loading}
              />
          </div>
        </div>
      </div>
    );
  };

  const renderSignNowSetting = () => {
    const isEnabled = isFeatureEnabled('SIGNNOW', availableFeatures);
    return (
      <div className="settings__item">
        <div className="title-primary settings__title">
          { t('SignNow API Settings') }
        </div>

        <div className="settings__fieldset">
          <div className="field field-radio__material">
            <div className="field__label">{ t('Setting type') }</div>

            <div className="field__wrap d__flex flex__row flex__wrap">
              <RadioTheme
                disabled={!editingAllowed || !isEnabled}
                isGrayText
                isRadioGroupHorizontal
                value="predefined"
                radioText={t('Predefined setting')}
                defaultChecked={(values || {}).signnow_setting_type === 'predefined'}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, signnow_setting_type: value })
                }
              />
              <RadioTheme
                disabled={!editingAllowed || !isEnabled}
                isGrayText
                isRadioGroupHorizontal
                value="personal"
                radioText={t('Use my own setting')}
                defaultChecked={(values || {}).signnow_setting_type === 'personal'}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, signnow_setting_type: value })
                }
              />
              <RadioTheme
                disabled={!editingAllowed || !isEnabled}
                isGrayText
                isRadioGroupHorizontal
                value="mail_only"
                radioText={t('Handle form manually by mail')}
                defaultChecked={(values || {}).signnow_setting_type === 'mail_only'}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, signnow_setting_type: value })
                }
              />
            </div>
          </div>
        </div>

        <br />

        <Grid container spacing={2}>
          {(values || {}).signnow_setting_type === 'personal' && (
            <>
              <Grid item xs={12} md={12}>
                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="signnow_api_key"
                  type="password"
                  label="SignNow API Key"
                  state={values}
                  setState={setValues}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="template_id_required_signature"
                  label="Template ID required Signature"
                  state={values}
                  setState={setValues}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="signnow_template_name"
                  label="Template Name required Signature"
                  state={values}
                  setState={setValues}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="signnow_individual_form_token_sale_agreement_id"
                  label="Template for Token Sale Agreement"
                  state={values}
                  setState={setValues}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="signnow_company_form_token_sale_agreement_id"
                  label="Template for Company Token Sale Agreement"
                  state={values}
                  setState={setValues}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="signnow_company_form_c_1_signature_template_id"
                  label="Template for Company Director Form C 1 Signatures"
                  state={values}
                  setState={setValues}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="signnow_company_form_c_2_signature_template_id"
                  label="Template for Company Director Form C 2 Signatures"
                  state={values}
                  setState={setValues}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="signnow_company_form_c_3_signature_template_id"
                  label="Template for Company Director Form C 3 Signatures"
                  state={values}
                  setState={setValues}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="signnow_company_form_c_4_signature_template_id"
                  label="Template for Company Director Form C 4 Signatures"
                  state={values}
                  setState={setValues}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="signnow_company_form_t_1_signature_template_id"
                  label="Template for Company Director Form T 1 Signature"
                  state={values}
                  setState={setValues}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="signnow_company_form_t_2_signature_template_id"
                  label="Template for Company Director Form T 2 Signatures"
                  state={values}
                  setState={setValues}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="signnow_company_form_t_3_signature_template_id"
                  label="Template for Company Director Form T 3 Signatures"
                  state={values}
                  setState={setValues}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="signnow_company_form_t_4_signature_template_id"
                  label="Template for Company Director Form T 4 Signatures"
                  state={values}
                  setState={setValues}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="signnow_individual_form_swap_token_template_id"
                  label="Template for Token Swap signature"
                  state={values}
                  setState={setValues}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="signnow_company_form_swap_token_template_id"
                  label="Template for Company Token Swap signature"
                  state={values}
                  setState={setValues}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="signnow_token_safe_id"
                  label="Template for Token SAFE signature"
                  state={values}
                  setState={setValues}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="signnow_account_email"
                  label="Account Email"
                  state={values}
                  setState={setValues}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="signnow_account_password"
                  type="password"
                  label="Account Password"
                  state={values}
                  setState={setValues}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                />
              </Grid>
            </>
          )}
        </Grid>

        <br />

        <div className="settings__fieldset">
          <div className="field">
            <div className="field__label">{ t('Options') }</div>

            <label className="checkbox">
              <input
                type="checkbox"
                className="checkbox__input"
                checked={values.enable_signnow_individual_form}
                disabled={!editingAllowed}
                onChange={onChangeCheckbox(
                  "enable_signnow_individual_form",
                  values,
                  setValues
                )}
                value={values.enable_signnow_individual_form}
              />

              <div className="checkbox__inner">
                <div className="checkbox__tick">{' '}</div>
                <div className="checkbox__text">
                  { t('Send document for individual') }
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
    );
  };

  const renderCryptoDotComSetting = () => {
    if (!isFeatureEnabled('ACRONIS_EXPORT', availableFeatures)) {
      return (
        <div className="settings__item">
          <div className="title-primary settings__title">
            {t('Crypto.com API Settings')}
            <LockedFeatureIcon />
          </div>
        </div>
      )
    }
    return (
      <div className="settings__item">
        <div className="title-primary settings__title title__clear-bar">{ t('Crypto.com API Settings') }</div>

        <div className="settings__fieldset">
          <div className="field create__line">
            <div className="create__info">
              { t('Enable Crypto.com Payment') }
            </div>

            <label className="switch">
              <input
                type="checkbox"
                className="switch__input"
                checked={values.enable_cryptodotcom}
                disabled={!editingAllowed}
                onChange={onChangeCheckbox(
                  "enable_cryptodotcom",
                  values,
                  setValues
                )}
                value={values.enable_cryptodotcom}
              />

              <div className="switch__inner">
                <div className="switch__box">{' '}</div>
              </div>
            </label>
          </div>

          <div className="settings__row flex__row flex__wrap">
            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="crypto_api_pk"
              type="text"
              label="Crypto API PK"
              state={values}
              setState={setValues}
              error={error}
              disabled={!editingAllowed}
              loading={loading}
            />

            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="crypto_api_sk"
              type="text"
              label="Crypto API SK"
              state={values}
              setState={setValues}
              error={error}
              disabled={!editingAllowed}
              loading={loading}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderAcronisSetting = () => {
    if (!isFeatureEnabled('ACRONIS_EXPORT', availableFeatures)) {
      return (
        <div className="settings__item">
          <div className="title-primary settings__title">
            { t('Acronis Synchronize Settings') }
            <LockedFeatureIcon />
          </div>
        </div>
      )
    }
    return (
      <div className="settings__item">
        <div className="title-primary settings__title">
          { t('Acronis Synchronize Settings') }
        </div>

        <div className="settings__fieldset">
          <div className="field create__line">
            <div className="create__info">
              { t("Enable Acronis Synchronize") }
            </div>
            <label className="switch">
              <input
                type="checkbox"
                className="switch__input"
                checked={values.enable_acronis}
                disabled={!editingAllowed}
                onChange={onChangeCheckbox(
                  "enable_acronis",
                  values,
                  setValues
                )}
                value={values.enable_acronis}
              />

              <div className="switch__inner">
                <div className="switch__box">{' '}</div>
              </div>
            </label>
          </div>

          <div className="settings__row flex__row flex__wrap have__fields">
            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="acronis_client_id"
              type="text"
              label="Acronis Client ID"
              state={values}
              setState={setValues}
              error={error}
              disabled={!editingAllowed}
              loading={loading}
            />

            {editingAllowed ? (
              <InputTheme
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"
                propertyName="acronis_client_secret"
                type="password"
                label="Acronis Client Secret"
                state={values}
                setState={setValues}
                error={error}
                disabled={!editingAllowed}
                loading={loading}
              />
            ) : (
              <InputTheme
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"
                propertyName="acronis_client_secret"
                type="password"
                label="Acronis Client Secret"
                state={{acronis_client_secret: 'password'}}
                setState={() => {}}
                error={error}
                disabled={!editingAllowed}
                loading={loading}
              />
            )}

            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="acronis_account_login"
              type="text"
              label="Acronis Account Login"
              state={values}
              setState={setValues}
              error={error}
              disabled={!editingAllowed}
              loading={loading}
            />

            {editingAllowed ? (
              <InputTheme
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"
                propertyName="acronis_account_password"
                type="password"
                label="Acronis Account Password"
                state={values}
                setState={setValues}
                error={error}
                disabled={!editingAllowed}
                loading={loading}
              />
            ) : (
              <InputTheme
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"
                propertyName="acronis_account_password"
                type="password"
                label="Acronis Account Password"
                state={{acronis_account_password: 'password'}}
                setState={() => {}}
                error={error}
                disabled={!editingAllowed}
                loading={loading}
              />
            )}

            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="acronis_root_folder"
              type="text"
              label="Acronis Root Folder UUID"
              state={values}
              setState={setValues}
              error={error}
              disabled={!editingAllowed}
              loading={loading}
            />
          </div>

        </div>
      </div>
    );
  };

  const renderTanganySetting = () => {
    return (
      <div className="settings__item">
        <div className="title-primary settings__title">
          { t('Tangany Service Settings') }
        </div>

        <div className="settings__fieldset">
          <div className="field create__line">
            <div className="create__info">
              { t("Enable Tangany Service") }
            </div>
            <label className="switch">
              <input
                type="checkbox"
                className="switch__input"
                checked={values.enable_tangany}
                disabled={!editingAllowed}
                onChange={onChangeCheckbox(
                  "enable_tangany",
                  values,
                  setValues
                )}
                value={values.enable_tangany}
              />

              <div className="switch__inner">
                <div className="switch__box">{' '}</div>
              </div>
            </label>
          </div>

          <div className="settings__fieldset">
            <div className="field">
              <div className="field__label">{ t('Setting type') }</div>

              <div className="field__wrap d__flex flex__row flex__wrap">
                <RadioTheme
                  disabled={!editingAllowed}
                  isGrayText
                  isRadioGroupHorizontal
                  value="predefined"
                  radioText={t('Predefined')}
                  nameGroup="tangany_setting_type"
                  defaultChecked={values.tangany_setting_type === 'predefined'}
                  onChange={({ target: { value } }) =>
                    setValues({ ...values, tangany_setting_type: value })
                  }
                />

                <RadioTheme
                  disabled={!editingAllowed}
                  isGrayText
                  isRadioGroupHorizontal
                  value="own_setting"
                  radioText={t('Own setting')}
                  nameGroup="tangany_setting_type"
                  defaultChecked={values.tangany_setting_type === 'own_setting'}
                  onChange={({ target: { value } }) =>
                    setValues({ ...values, tangany_setting_type: value })
                  }
                />
              </div>
            </div>
          </div>

          <br />

          {values.tangany_setting_type === 'own_setting' && (
            <div className="settings__row flex__row flex__wrap have__fields">
              <InputTheme
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"
                propertyName="tangany_client_id"
                type="text"
                label="Tangany Client ID"
                state={values}
                setState={setValues}
                error={error}
                disabled={!editingAllowed}
                loading={loading}
              />

              {editingAllowed ? (
                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="tangany_client_secret"
                  type="password"
                  label="Tangany Client Secret"
                  state={values}
                  setState={setValues}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                />
              ) : (
                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="tangany_client_secret"
                  type="password"
                  label="Tangany Client Secret"
                  state={{tangany_client_secret: 'password'}}
                  setState={() => {}}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                />
              )}

              <InputTheme
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"
                propertyName="tangany_vault_url"
                type="text"
                label="Tangany Vault URL"
                state={values}
                setState={setValues}
                error={error}
                disabled={!editingAllowed}
                loading={loading}
              />

              {editingAllowed ? (
                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="tangany_subscription"
                  type="password"
                  label="Tangany subscription"
                  state={values}
                  setState={setValues}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                />
              ) : (
                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="tangany_subscription"
                  type="password"
                  label="Tangany subscription"
                  state={{tangany_subscription: 'password'}}
                  setState={() => {}}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                />
              )}
            </div>
          )}

        </div>
      </div>
    );
  };

  const renderImpiraSetting = () => {
    return (
      <div className="settings__item">
        <div className="title-primary settings__title">
          { t('Impira Service Settings') }
        </div>

        <div className="settings__fieldset">
          <div className="field create__line">
            <div className="create__info">
              { t("Enable Impira Service") }
            </div>
            <label className="switch">
              <input
                type="checkbox"
                className="switch__input"
                checked={values.enable_impira}
                disabled={!editingAllowed}
                onChange={onChangeCheckbox(
                  "enable_impira",
                  values,
                  setValues
                )}
                value={values.enable_impira}
              />

              <div className="switch__inner">
                <div className="switch__box">{' '}</div>
              </div>
            </label>
          </div>

          <div className="settings__fieldset">
            <div className="field">
              <div className="field__label">{ t('Setting type') }</div>

              <div className="field__wrap d__flex flex__row flex__wrap">
                <RadioTheme
                  disabled={!editingAllowed}
                  isGrayText
                  isRadioGroupHorizontal
                  value="predefined"
                  radioText={t('Predefined')}
                  nameGroup="impira_setting_type"
                  defaultChecked={values.impira_setting_type === 'predefined'}
                  onChange={({ target: { value } }) =>
                    setValues({ ...values, impira_setting_type: value })
                  }
                />

                <RadioTheme
                  disabled={!editingAllowed}
                  isGrayText
                  isRadioGroupHorizontal
                  value="own_setting"
                  radioText={t('Own setting')}
                  nameGroup="impira_setting_type"
                  defaultChecked={values.impira_setting_type === 'own_setting'}
                  onChange={({ target: { value } }) =>
                    setValues({ ...values, impira_setting_type: value })
                  }
                />
              </div>
            </div>
          </div>

          <br />

          {values.impira_setting_type === 'own_setting' && (
            <div className="settings__row flex__row flex__wrap have__fields">
              <InputTheme
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"
                propertyName="impira_org"
                type="text"
                label="Impira Organizer ID"
                state={values}
                setState={setValues}
                error={error}
                disabled={!editingAllowed}
                loading={loading}
              />

              {editingAllowed ? (
                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="impira_token"
                  type="password"
                  label="Impira Access Token"
                  state={values}
                  setState={setValues}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                />
              ) : (
                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="impira_token"
                  type="password"
                  label="Impira Client Secret"
                  state={{impira_token: 'password'}}
                  setState={() => {}}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                />
              )}

              <InputTheme
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"
                propertyName="impira_utility_bill"
                type="text"
                label="Impira Utility Bill Collection"
                state={values}
                setState={setValues}
                error={error}
                disabled={!editingAllowed}
                loading={loading}
              />
            </div>
          )}

        </div>
      </div>
    );
  };

  const renderTwilioSetting = () => {
    return (
      <div className="settings__item">
        <div className="title-primary settings__title">
          { t('Twilio Service Settings') }
        </div>

        <div className="settings__fieldset">
          <div className="field">
            <div className="field__label">{ t('Setting type') }</div>

            <div className="field__wrap d__flex flex__row flex__wrap">
              <RadioTheme
                disabled={!editingAllowed}
                isGrayText
                isRadioGroupHorizontal
                value="predefined"
                radioText={t('Predefined')}
                nameGroup="twilio_setting_type"
                defaultChecked={values.twilio_setting_type === 'predefined'}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, twilio_setting_type: value })
                }
              />

              <RadioTheme
                disabled={!editingAllowed}
                isGrayText
                isRadioGroupHorizontal
                value="own_setting"
                radioText={t('Own setting')}
                nameGroup="twilio_setting_type"
                defaultChecked={values.twilio_setting_type === 'own_setting'}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, twilio_setting_type: value })
                }
              />
            </div>
          </div>

          <br />

          {values.twilio_setting_type === 'own_setting' && (
            <div className="settings__row flex__row flex__wrap have__fields">
              <InputTheme
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"
                propertyName="twilio_account_sid"
                type="text"
                label="Twilio Account SID"
                state={values}
                setState={setValues}
                error={error}
                disabled={!editingAllowed}
                loading={loading}
              />

              {editingAllowed ? (
                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="twilio_auth_token"
                  type="password"
                  label="Twilio Auth Token"
                  state={values}
                  setState={setValues}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                />
              ) : (
                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="twilio_auth_token"
                  type="password"
                  label="Twilio Service SID"
                  state={{twilio_auth_token: 'password'}}
                  setState={() => {}}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                />
              )}

              <InputTheme
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"
                propertyName="twilio_service_sid"
                type="text"
                label="Twilio Service SID"
                state={values}
                setState={setValues}
                error={error}
                disabled={!editingAllowed}
                loading={loading}
              />
            </div>
          )}

        </div>
      </div>
    );
  };

  const renderDeeplSetting = () => {
    return (
      <div className="settings__item">
        <div className="title-primary settings__title">
          { t('Deepl Service Settings') }
        </div>

        <div className="settings__fieldset">
          <div className="field">
            <div className="field__label">{ t('Setting type') }</div>

            <div className="field__wrap d__flex flex__row flex__wrap">
              <RadioTheme
                disabled={!editingAllowed}
                isGrayText
                isRadioGroupHorizontal
                value="predefined"
                radioText={t('Predefined')}
                nameGroup="deepl_setting_type"
                defaultChecked={values.deepl_setting_type === 'predefined'}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, deepl_setting_type: value })
                }
              />

              <RadioTheme
                disabled={!editingAllowed}
                isGrayText
                isRadioGroupHorizontal
                value="own_setting"
                radioText={t('Own setting')}
                nameGroup="deepl_setting_type"
                defaultChecked={values.deepl_setting_type === 'own_setting'}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, deepl_setting_type: value })
                }
              />
            </div>
          </div>

          <br />

          {values.deepl_setting_type === 'own_setting' && (
            <div className="settings__row flex__row flex__wrap have__fields">
              {editingAllowed ? (
                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="deepl_auth_key"
                  type="password"
                  label="Deepl Auth Key"
                  state={values}
                  setState={setValues}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                />
              ) : (
                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName="deepl_auth_key"
                  type="password"
                  label="Deepl Auth Key"
                  state={{deepl_auth_key: 'password'}}
                  setState={() => {}}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderAllowListCountries = () => {
    return (
      <div className="settings__item">
        <div className="title-primary settings__title">
          {t('Allow List Countries')}
        </div>

        <div className="settings__fieldset">
          <div className="field">
            <SwitchCheckbox
              isRemoveLine
              defaultChecked={values.enable_allow_list_countries}
              onChange={onChangeCheckbox(
                "enable_allow_list_countries",
                values,
                setValues
              )}
              value={values.enable_allow_list_countries}
              isDisabled={!editingAllowed}
              switchText={
                <>
                  {t('Enable Allow List Countries')}
                </>
              }
            />
          </div>

          {values.enable_allow_list_countries && (
            <>
              <div className="field">
                <div className="field__label">{t('Current Allow List Countries')}</div>

                <div className="field__wrap">
                  <input
                    className="field__input"
                    type="text"
                    value={values.allow_list_countries_choosen === null ? "empty" : values.allow_list_countries_choosen}
                    disabled
                  />
                </div>
              </div>

              {checkedCitizens === null ? '' : (
                <div className="field">
                  <div className="field__label">{`Allow List Countries are: ${checkedCitizens} `}</div>
                </div>
              )}

              <div className="field field__radio-kyc-style-1 field__radio-kyc-style-1_1">
                <div className="d__flex flex__wrap flex__row field field__radio-kyc-style-1 field__radio-kyc__style-1_2">
                  {COUNTRY_LIST.map((country) => (
                    <label className="checkbox" key={country}>
                      <input
                        type="checkbox"
                        className="checkbox__input"
                        onChange={handleCheckAllowlistCountries}
                        checked={checkedAllowlistCountries && checkedAllowlistCountries.includes(country)}
                        value={country}
                      />
                      <div className="checkbox__inner">
                        <div className="checkbox__tick">{' '}</div>
                        <div className="checkbox__text">{country}</div>
                      </div>

                    </label>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    )
  }

  const renderAllowListNationality = () => {
    return (
      <div className="settings__item">
        <div className="title-primary settings__title">
          {t('Allow List Nationality')}
        </div>

        <div className="settings__fieldset">
          <div className="field">
            <SwitchCheckbox
              isRemoveLine
              defaultChecked={values.enable_allow_list_nationality}
              onChange={onChangeCheckbox(
                "enable_allow_list_nationality",
                values,
                setValues
              )}
              value={values.enable_allow_list_nationality}
              isDisabled={!editingAllowed}
              switchText={
                <>
                  {t('Enable Allow List Nationality')}
                </>
              }
            />
          </div>

          {values.enable_allow_list_nationality && (
            <>
              <div className="field">
                <div className="field__label">{t('Current Allow List Nationality')}</div>

                <div className="field__wrap">
                  <input
                    className="field__input"
                    type="text"
                    value={values.allow_list_nationality_choosen === null ? "empty" : values.allow_list_nationality_choosen}
                    disabled
                  />
                </div>
              </div>

              {checkedNationalities === null ? '' : (
                <div className="field">
                  <div className="field__label">{`Allow List Nationality are: ${checkedNationalities} `}</div>
                </div>
              )}

              <div className="field field__radio-kyc-style-1 field__radio-kyc-style-1_1">
                <div className="d__flex flex__wrap flex__row field field__radio-kyc-style-1 field__radio-kyc__style-1_2">
                  {COUNTRY_LIST.map((country) => (
                    <label className="checkbox" key={country}>
                      <input
                        type="checkbox"
                        className="checkbox__input"
                        onChange={handleCheckAllowlistNationality}
                        checked={checkedAllowlistNationality && checkedAllowlistNationality.includes(country)}
                        value={country}
                      />
                      <div className="checkbox__inner">
                        <div className="checkbox__tick">{' '}</div>
                        <div className="checkbox__text">{country}</div>
                      </div>

                    </label>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      {renderTokenSettings()}
      {renderPurchaseAgreementLinkSettings()}
      {renderSubscriptionAgreementLinkSettings()}
      {renderEnableDiscountLevelOption()}
      {renderCurrencySettings()}
      {renderVideoIdentValueSettings()}
      {renderGoogleAnalyticsSettings()}
      {renderFieldLoginPageSettings()}
      {renderFieldsSendEmail()}
      {renderOtherFields()}
      {renderInternalSalesApiSettings()}
      {renderKycReferralCodeOption()}
      {renderWalletServices()}
      {renderSendwyreApiSettings()}
      {renderHotjarSetting()}
      {renderIntercomAppIdSetting()}
      {renderCryptoDotComSetting()}
      {renderVoltIoSetting()}
      {renderStripeSetting()}
      {renderMoneriumSetting()}
      {renderSignNowSetting()}
      {renderAcronisSetting()}
      {renderTanganySetting()}
      {/* {renderImpiraSetting()} */}
      {renderTwilioSetting()}
      {renderDeeplSetting()}
      {renderAllowListCountries()}
      {renderAllowListNationality()}

      {!isAllRequiredFeaturesEnabled([
        'GOOGLE_ANALYTICS',
        'GOOGLE_TAG_MANAGER',
        'FACEBOOK_PIXEL',
        'YOUTUBE_LOGIN_PAGE',
        'AUTO_FOLLOW_UP',
        'SALES_API',
        'ALLOW_NON_ERC20',
        'REFERRAL_CODE',
        'SENDWYRE',
        'WALLET_ENGINE',
        'SENDWYRE',
        'HOTJAR_INTEGRATION',
        'SALES_API',
        'DISCOUNT_LEVEL',
        'VOLT_IO',
        'SIGNNOW',
        'ACRONIS_EXPORT',
      ], availableFeatures) && (
        <FeatureNotAvailableMessage />
      )}
      {loading ? (
        <div className="settings__item">
          <CircularProgress />
        </div>
      ) : (
        <button
          disabled={!editingAllowed}
          onClick={onUpdateBtnClick}
          className="button settings__button"
        >
          { t('Update') }
        </button>
      )}
    </>
  );
};

AdminSettingsIco.propTypes = {
  settings: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
};

export default AdminSettingsIco;
